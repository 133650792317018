// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA
import env from "@/config/env"
import { socket } from "@/config/socket"
export const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === "[::1]" ||
        // 127.0.0.0/8 are considered localhost for IPv4.
        window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
)

type Config = {
    onSuccess?: (registration: ServiceWorkerRegistration) => void
    onUpdate?: (version: string) => void
}

class ServiceWorker {
    // This optional code is used to register a service worker.
    register(config?: Config) {
        if ("serviceWorker" in navigator) {
            // The URL constructor is available in all browsers that support SW.
            const publicUrl = new URL("/", window.location.href)

            if (publicUrl.origin !== window.location.origin) {
                // Our service worker won't work if PUBLIC_URL is on a different origin
                // from what our page is served on. This might happen if a CDN is used to
                // serve assets; see https://github.com/facebook/create-react-app/issues/2374
                return
            }

            window.addEventListener("load", () => {
                const swUrl = `/service-worker.js`
                this.runServiceWorker(swUrl, config)
            })
        }
    }

    runServiceWorker(swUrl: string, config?: Config) {
        this.checkSwFile(swUrl, (exist: boolean) => {
            if (exist) {
                this.checkValidServiceWorker(
                    swUrl,
                    () => {
                        this.registerValidSW(swUrl, config)
                    },
                    config
                )
            }
        })
    }

    registerValidSW(swUrl: string, config?: Config) {
        // Unregister other duplicated service workers
        const self = this
        navigator.serviceWorker.getRegistrations().then((registrations) => {
            registrations.forEach((registration) => {
                registration.unregister()
            })
            // Register the new service worker after unregistering the old one
            navigator.serviceWorker
                .register(swUrl, {
                    updateViaCache: "none",
                })
                .then((registration) => {
                    registration.update()
                })
                .catch((error) => {
                    console.error("Error during service worker registration:", error)
                })
            self.setupSocketListeners(swUrl, config)
        })
    }

    setupSocketListeners(swUrl: string, config: Config) {
        socket.on("connect", () => {
            console.log("Connected to server")
        })

        socket.on("notification", (data) => {
            this.handleVersionUpdate(swUrl, config, data?.title, data?.version)
        })

        socket.on("currentVersion", (sid, version) => {
            console.log("sid :", sid)
            this.handleVersionUpdate(swUrl, config, "CURRENT_VERSION", version?.current_version)
        })
    }

    handleVersionUpdate(swUrl: string, config: Config, title: string, serverVersion: string) {
        const version = localStorage.getItem(`${env.APP_NAME}-${env.ENV}-version`)
        if (title === "NEW_APP_VERSION" || title === "CURRENT_VERSION") {
            if (version !== serverVersion) {
                navigator.serviceWorker
                    .register(swUrl, { updateViaCache: "none" })
                    .then((registration) => {
                        registration.update()
                        if (config?.onUpdate) {
                            config.onUpdate(serverVersion)
                        }
                    })
                    .catch((error) => {
                        console.error("Error during service worker update:", error)
                    })
            } else {
                localStorage.setItem(`${env.APP_NAME}-${env.ENV}-version`, serverVersion)
            }
        }
    }

    checkValidServiceWorker(swUrl: string, callback: () => void, config?: Config) {
        // Check if the service worker can be found. If it can't reload the page.
        fetch(swUrl, { headers: { "Service-Worker": "script" } })
            .then((response) => {
                if (response.status === 404 || response.headers.get("content-type").indexOf("javascript") === -1) {
                    console.error(
                        "Service worker not found or invalid content type:",
                        response.status,
                        response.headers.get("content-type")
                    )
                    navigator.serviceWorker.ready.then((registration) => {
                        registration.unregister()
                    })
                } else {
                    callback()
                }
            })
            .catch((error) => {
                console.error("Error fetching service worker script:", error)
            })
    }

    checkSwFile = (url: string, callback: (data: boolean) => void) => {
        const xhr = new XMLHttpRequest()
        xhr.open("HEAD", url, true)
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    // Le fichier existe
                    callback(true)
                } else {
                    // Le fichier n'existe pas ou il y a une erreur
                    callback(false)
                }
            }
        }
        xhr.send()
    }

    unregister() {
        if ("serviceWorker" in navigator) {
            navigator.serviceWorker.ready
                .then((registration) => {
                    registration.unregister()
                })
                .catch((error) => {
                    console.error(error.message)
                })
        }
    }
}

export default new ServiceWorker()
