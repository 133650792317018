import { io } from "socket.io-client"
import env from "../config/env"

export const socket = io(`${env.GW_BASE_URL}`, {
    path: `/${env.WEBSOCKET_HANDSHAKE_PATH}/socket.io`,
    transports: ["websocket"],
    query: {
        env: `${env.APP_NAME}-${env.ENV}`,
    },
})
