import NotificationStore from "@/store/notification"
import UiStore from "@/store/ui"
import { Box } from "@mui/material"
import classNames from "classnames"
import SnackbarError from "./snackbar-error"
import SnackbarNewVersion from "./snackbar-new-version"
import SnackbarSuccess from "./snackbar-success"
import useStyles from "./style"

const Notification = () => {
    const { classes } = useStyles()
    const { type, show } = NotificationStore()
    const { showNewVersion } = UiStore()

    return (
        <>
            <Box className={classNames(classes.snackbarsWrapper, show && "shown")}>
                {type === "ERROR" && <SnackbarError />}
                {type === "SUCCESS" && <SnackbarSuccess />}
            </Box>
            {showNewVersion && <SnackbarNewVersion />}
        </>
    )
}

export default Notification
