import Icon from "@/components/base/Icon"
import FormAction from "@/components/base/form-action"
import TitleWrapper from "@/components/base/title-wrapper"
import FilterFormIg from "@/components/instagram/form/filter-form"
import useStyles from "@/components/multi-business/styles"
import useLogic from "@/hooks/facebook/useLogic"
import { Box, CircularProgress, FormControlLabel, List, Radio, RadioGroup, Stack } from "@mui/material"
import { useTranslation } from "react-i18next"
const ProgressComponent = () => {
    return (
        <Stack
            className="list-wrapper"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            sx={{
                textAlign: "center",
            }}
        >
            <CircularProgress />
        </Stack>
    )
}
const AuthFacebook2 = ({ mockData = null }) => {
    const { classes } = useStyles({ props: { haveTop: false } })
    const { t } = useTranslation()
    const {
        handleCancel,
        pages,
        handleSync,
        isFetching,
        isLoading,
        selectedPage,
        setSelectedPage,
        handlePrevious,
        fetchPages,
    } = useLogic()
    return (
        <Box className={classes.container}>
            <Box className="wrapper">
                <Stack direction={{ sm: "row" }} gap={2} justifyContent={"space-between"}>
                    <TitleWrapper
                        handlePreviousButton={handlePrevious}
                        title={t("BUSINESSES.INSTAGRAM_AUTH.TITLE")}
                        subtitle={false}
                    />
                </Stack>
                <Box className="list-wrapper">
                    <FilterFormIg
                        disabled={isFetching}
                        onRefresh={(filter) => fetchPages(filter)}
                        addAccount={handlePrevious}
                    />

                    {isFetching && <ProgressComponent />}
                    {!isFetching && (mockData || pages)?.length > 0 && (
                        <Box className="">
                            <RadioGroup
                                onChange={(event) => setSelectedPage(event.target.value)}
                                defaultValue={selectedPage}
                                aria-labelledby="radio-buttons-group-label"
                                name="radio-buttons-group"
                                sx={{
                                    "& .MuiSvgIcon-root": {
                                        fill: "#0180FF!important",
                                    },
                                }}
                            >
                                <List>
                                    {(mockData || pages)?.map((page, kkey) => (
                                        <div className="radio-element" key={kkey} data-testid="page-facebook-item">
                                            <FormControlLabel
                                                sx={{ width: "100%" }}
                                                value={page?.ig_id}
                                                control={<Radio disabled={page?.is_connected} />}
                                                label={page?.ig_username}
                                            />
                                            {page?.is_connected && <Icon name="link" />}
                                        </div>
                                    ))}
                                </List>
                            </RadioGroup>
                        </Box>
                    )}
                </Box>
                <FormAction
                    handleSubmit={handleSync}
                    handleCancel={handleCancel}
                    isDisabled={isFetching || !selectedPage}
                    isLoading={isLoading}
                    isFacebook={true}
                />
            </Box>
        </Box>
    )
}

export default AuthFacebook2
